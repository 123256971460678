var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"nationals"},[_c('main',[_c('div',{staticClass:"bg"},[_c('video',{attrs:{"playsinline":"","autoplay":"","loop":"","muted":"","poster":"https://cms.aplesports.com/storage/uploads/2021/02/13/6027fdac525c5unknown.png"},domProps:{"muted":true}},[_c('source',{attrs:{"src":"/flag-bg.webm","type":"video/webm"}})])]),_c('div',{staticClass:"container presenting"},[_vm._v(" Presenting ")]),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7)]),_c('div',{staticClass:"footer"},[_vm._v(" Rocket League, Psyonix, and all related marks and logos are trademarks or registered trademarks of Psyonix LLC and used under license. © 2015-2020 Licensed by Psyonix LLC, All rights reserved. All other trademarks are property of their respective owners. ")])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container brand"},[_c('img',{attrs:{"src":"https://cms.aplesports.com/storage/uploads/2021/02/11/6024e388e7fd9APL_Nationals_Logo-Flag-white.png","alt":"APL Nationals Logo Flag"}}),_c('img',{attrs:{"src":"https://cms.aplesports.com/storage/uploads/2021/02/14/6028fd6eeac77Nationals-Text-Slim.gif","alt":"APL Nationals Logo Text"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container psyonix"},[_c('img',{attrs:{"src":"https://cms.aplesports.com/storage/uploads/2020/06/03/5ed7144204020Sponsored_By_Psyonix.png","alt":"Sponsored by Psyonix"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container introp"},[_vm._v(" Welcome to the APL Nationals! With $25,000 on the line across five countries, it's set to be an exciting month of national competition. Teams from Saudi Arabia, Japan, Indonesia, Singapore, and India will be competing for their share of the Psyonix-sponsored prize pool, so get your team together and sign up now! "),_c('div',[_c('a',{staticClass:"btn",attrs:{"href":"https://cms.aplesports.com/storage/uploads/2021/02/10/602424bbcbc6dAPL-NATIONALS---Official-Ruleset-v1.0.pdf","target":"_blank"}},[_vm._v("Rulebook")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container poster heading"},[_c('img',{attrs:{"src":"https://cms.aplesports.com/storage/uploads/2021/02/13/602750fe497e7APLN-Website-Banner.jpg","alt":"APL Nationals Poster"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container tier t1"},[_c('ul',[_c('div',[_vm._v(" Saudi Arabia "),_c('img',{attrs:{"src":"https://upload.wikimedia.org/wikipedia/commons/0/0d/Flag_of_Saudi_Arabia.svg","alt":"Saudi Arabia Flag"}})]),_c('li',[_vm._v("Qualifier: Mar 12, 2021 5PM KSA")]),_c('li',[_vm._v("Last Chance Qualifier: Mar 19, 2021 5PM KSA")]),_c('li',[_vm._v("Group Stage: Mar 26, 2021 7PM KSA")]),_c('li',[_vm._v("Playoffs: Apr 2, 2021 7PM KSA")]),_c('a',{staticClass:"btn",attrs:{"href":"https://smash.gg/SaudiArabianNational","target":"_blank"}},[_vm._v("Register")])]),_c('ul',[_c('div',[_vm._v(" Japan "),_c('img',{attrs:{"src":"https://upload.wikimedia.org/wikipedia/en/9/9e/Flag_of_Japan.svg","alt":"Japan Flag"}})]),_c('li',[_vm._v("Qualifier: Mar 13, 2021 6PM JST")]),_c('li',[_vm._v("Last Chance Qualifier: Mar 20, 2021 6PM JST")]),_c('li',[_vm._v("Group Stage: Mar 27, 2021 7PM JST")]),_c('li',[_vm._v("Playoffs: Apr 3, 2021 7PM JST")]),_c('a',{staticClass:"btn",attrs:{"href":"https://smash.gg/JapaneseNational","target":"_blank"}},[_vm._v("Register")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container tier t2"},[_c('ul',[_c('div',[_vm._v(" Indonesia "),_c('img',{attrs:{"src":"https://upload.wikimedia.org/wikipedia/commons/9/9f/Flag_of_Indonesia.svg","alt":"Indonesia Flag"}})]),_c('li',[_vm._v("Qualifier: Mar 21, 2021 5PM WIB")]),_c('li',[_vm._v("Finals: Mar 28, 2021 7PM WIB")]),_c('a',{staticClass:"btn",attrs:{"href":"https://smash.gg/IndonesianNational","target":"_blank"}},[_vm._v("Register")])]),_c('ul',[_c('div',[_vm._v(" Singapore "),_c('img',{attrs:{"src":"https://upload.wikimedia.org/wikipedia/commons/4/48/Flag_of_Singapore.svg","alt":"Singapore Flag"}})]),_c('li',[_vm._v("Qualifier: Mar 7, 2021 5PM SGT")]),_c('li',[_vm._v("Finals: Mar 14, 2021 7PM SGT")]),_c('a',{staticClass:"btn",attrs:{"href":"https://smash.gg/SingaporeanNational","target":"_blank"}},[_vm._v("Register")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container tier t3"},[_c('ul',[_c('div',[_vm._v(" India "),_c('img',{attrs:{"src":"https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png","alt":"India Flag"}})]),_c('li',[_vm._v("Qualifier: Apr 10, 2021 5PM IST")]),_c('li',[_vm._v("Finals: Apr 11, 2021 7PM IST")]),_c('a',{staticClass:"btn",attrs:{"href":"https://smash.gg/IndianNational","target":"_blank"}},[_vm._v("Register")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container trailer"},[_c('iframe',{attrs:{"src":"https://www.youtube.com/embed/7mZzs6-4_Nw","frameborder":"0","allow":"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture","allowfullscreen":""}})])
}]

export { render, staticRenderFns }